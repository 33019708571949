
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.addlist {
  h3 {
    display: flex;

    &::before {
      content: "";
      border-left: 3px solid rgb(92, 107, 232);
      height: 1rem;
      top: 0.15rem;
      position: relative;
    }

    span {
      margin-left: 20px;
    }

    margin-bottom: 30px;
  }
}

.zsBtn {
  margin-left: 20px;

  button {
    height: 40px;
  }
}

.certificate-box {
  > div {
    margin-bottom: 5px;
  }

  > div:last-child {
    border: 0;
    margin-bottom: 0;
  }
}

.form-box {
  padding: 10px 20px;

  .forms {
    width: 800px !important;
    padding: 20px;

    .el-form-item__content {
      width: 100%;
    }
  }

  .form-bg {
    background-color: rgb(247, 248, 253);

    .el-form-item__content {
      width: 80%;
    }
  }
}

.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 70%;

  .el-button {
    width: 10rem;
  }
}

.tp {
  width: 50%;
  float: left;

  .img-el-upload {
    width: 63%;
    float: left;
    margin-right: 2%;
  }

  .tswz {
    float: left;
    font-size: 12px;
    line-height: 25px;
    color: #909399;

    .t1 {
      font-size: 14px;
      line-height: 40px;
      color: #606266;
    }
  }
}

.tip {
  position: relative;
  z-index: 100;
  width: 400px;
  background-color: #fff;
  box-shadow: 0 0 10px #eee;
  white-space: nowrap;
}
